<template>
    <b-modal ref="declarer-pointage" hide-footer centered>
        <template v-slot:modal-header>
            <div class="">
                <span class="text-muted" style="position: absolute; right: 10px; top: 5px" @click="close()">x</span>
                <h5 class="text-info">
                    <strong>{{ intervention.customer_title }}.
                        {{ intervention.customer_firstname }}
                        {{ intervention.customer_lastname }}</strong>
                </h5>
                <div>
                    Intervention du
                    {{ $moment(intervention.date_slot).format("D MMMM") }}
                </div>
            </div>
        </template>
        <template v-slot:default>
            <b-container fluid>
                <div class="row">
                    <label for="">Prestation de :</label>
                    <select class="form-control" name="" id="" v-model="pointage.idactivity">
                        <option v-for="activity in activities" class="" :value="activity.idbusiness_activity"
                            :key="activity.idbusiness_activity">
                            {{ activity.name }}
                        </option>
                    </select>
                </div>
                <div class="row mt-3">
                    <div class="col-6 ml-0 pl-0">
                        <label for="">De :</label>
                        <input class="form-control" v-model="pointage.start_time" type="time" />
                    </div>
                    <div class="col-6 mr-0 pr-0">
                        <label for="">À :</label>
                        <input class="form-control" v-model="pointage.end_time" type="time" />
                    </div>
                    <div class="col-6"></div>
                    <div class="col-6">
                        <span class="text-muted">(soit {{ getTimeString() }})</span>
                    </div>
                </div>
                <div class="row mt-3" v-if="isGfs()">
                    <div class="col-6 ml-0 pl-0">
                        <label for="">Kms à déclarer :</label>
                        <input class="form-control" v-model="pointage.kms" type="number" min="0" step="1" />
                    </div>
                    <div class="col-6 mr-0 pr-0">
                        <!--                        <label for="">Paniers repas :</label>-->
                        <!--                        <input class="form-control" v-model="pointage.lunchbox" type="number" min="0" step="1" max="3">-->
                    </div>
                </div>
                <div class="row mt-3 d-flex justify-content-center">
                    <button class="btn btn-primary" :disabled="!pointage.idemployee_contract" @click="declarerPointage()">
                        <h3 class="pt-1 px-3">Déclarer</h3>
                    </button>
                </div>
                <div class="row mt-4 text-muted d-flex justify-content-center">
                    <div class="position-absolute border-bottom" style="width: 80%"></div>
                    <div class="position-relative bg-white" style="margin-top: -0.75rem">
                        <div class="px-4">ou</div>
                    </div>
                </div>
                <div class="row mt-3 d-flex justify-content-center">
                    <button v-if="!cancelClicked" class="btn btn-outline-info" :disabled="!idemployee"
                        @click="cancelClicked = true">
                        Je n'ai pas fait la prestation
                    </button>
                    <transition name="fade">
                        <div v-if="cancelClicked" class="col-12">
                            <div class="row">
                                <div class="col-12 text-center mb-2">L'annulation vient :</div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <button class="w-100 btn btn-outline-info" :disabled="!idemployee"
                                        @click="cancelSlot(true)">
                                        De moi
                                    </button>
                                </div>
                                <div class="col-6">
                                    <button class="w-100 btn btn-outline-info" :disabled="!idemployee"
                                        @click="cancelSlot(false)">
                                        Du client
                                    </button>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </b-container>
        </template>
    </b-modal>
</template>

<script>
import pointageService from "@/services/pointageService";
import userService from "@/services/userService";
import planningService from "@/services/planningService";

export default {
    name: "DeclarerPointage",
    props: {
        intervention: {
            type: Object,
        },
        activities: {
            type: Array,
        },
    },
    watch: {
        "pointage.lunchbox"(val) {
            this.pointage.lunchbox = Math.min(Math.max(0, val), 3);
        },
    },
    data() {
        return {
            pointage: {
                start_time: null,
                end_time: null,
                idagenda_slot: null,
                kms: 0,
                lunchbox: 0,
                idactivity: null,
                intervention_date: null,
                idemployee_contract: null,
                contract_idcontract: null,
            },
            idemployee: 0,
            cancelClicked: false,
        };
    },
    methods: {
        initPointage() {
            this.pointage.start_time = this.$props.intervention.start_time;
            this.pointage.end_time = this.$props.intervention.end_time;
            this.pointage.intervention_date = this.$props.intervention.date_slot;
            this.pointage.idagenda_slot = this.$props.intervention.idagenda_slot;
            this.pointage.contract_idcontract = this.$props.intervention.contract_idcontract;
            this.pointage.idactivity = this.$props.intervention.business_activity_idbusiness_activity;
            this.pointage.idemployee_contract = null;
            this.pointage.kms = 0;
            this.pointage.lunchbox = 0;

            userService
                .guessContract(this.$props.intervention.customer_idcustomer)
                .then((res) => {
                    if (res.data.etat === "OK") {
                        this.$set(this.pointage, "idemployee_contract", res.data.data.idemployee_contract);
                        this.idemployee = res.data.data.employee_idemployee;
                    }
                })
                .catch((error) => {
                    this.$rollbar.error("Erreur 3024", error);
                    this.$bvToast.toast("Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.", {
                        title: "Erreur 3024",
                        autoHideDelay: 5000,
                        variant: "danger",
                    });
                });
        },
        show() {
            this.$nextTick(() => {
                this.$refs["declarer-pointage"].show();
                if (this.$props.intervention) {
                    this.initPointage();
                }
            });
        },
        close() {
            this.$refs["declarer-pointage"].hide();
        },
        getTimeString() {
            const startDay = this.pointage.intervention_date;
            const endDay =
                this.pointage.end_time < this.pointage.start_time
                    ? this.$moment(this.pointage.intervention_date).add(1, "day").format("YYYY-MM-DD")
                    : this.pointage.intervention_date;
            const duration = this.$moment.duration(
                this.$moment(endDay + " " + this.pointage.end_time).diff(this.$moment(startDay + " " + this.pointage.start_time))
            );

            const hours = Math.floor(duration.asHours());
            const minutes = Math.ceil(duration.asMinutes() % 60);

            return `${hours}h ${minutes}m`;
        },
        async declarerPointage() {
            const pointage = { ...this.pointage };

            // on verfie que ni le pointage.end_time ni le pointage.start_time ne sont dans le futur (si c'est le cas on lève une erreur)
            if (this.$moment(pointage.intervention_date + " " + pointage.start_time).isAfter(this.$moment())) {
                this.$bvToast.toast("Vous ne pouvez pas déclarer un pointage d'entrée dans le futur.", {
                    title: "Erreur 3064",
                    autoHideDelay: 5000,
                    variant: "danger",
                });
                return;
            } else if (this.$moment(pointage.intervention_date + " " + pointage.end_time).isAfter(this.$moment())) {
                this.$bvToast.toast("Vous ne pouvez pas déclarer un pointage de sortie dans le futur.", {
                    title: "Erreur 3059",
                    autoHideDelay: 5000,
                    variant: "danger",
                });
                return;
            }

            if (pointage.end_time < pointage.start_time && pointage.end_time != '00:00:00' && pointage.end_time != '00:00') {
                const pointage2 = { ...this.pointage };
                pointage.end_time = "00:00:00";
                pointage2.start_time = "00:00:00";
                pointage2.intervention_date = this.$moment(pointage.intervention_date).add(1, "day").format("YYYY-MM-DD");
                await pointageService.declarerPointage(pointage2);
            }
            pointageService
                .declarerPointage(pointage)
                .then((res) => {
                    if (res.data.etat == "OK") {
                        this.$emit("submit");
                    } else {
                        this.$rollbar.error("Erreur 3064");
                        this.$bvToast.toast("Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.", {
                            title: "Erreur 3064",
                            autoHideDelay: 5000,
                            variant: "danger",
                        });
                    }
                })
                .catch((error) => {
                    this.$rollbar.error("Erreur 3025", error);
                    this.$bvToast.toast("Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.", {
                        title: "Erreur 3025",
                        autoHideDelay: 5000,
                        variant: "danger",
                    });
                });
        },
        cancelSlot(employeeCancel) {
            planningService.cancelPastSlot({
                idagenda_slot: this.$props.intervention.idagenda_slot,
                idcancel_category: employeeCancel ? 2 : 15,
                idemployee: this.idemployee,
            });
            this.$emit("submit");
        },
        isGfs() {
            return location.host.includes("family");
        },
    },
};
</script>

<style scoped>
#selectCallback {
    background: #cbf0ff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%232f3744' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}
</style>
