<template>
    <div id="declaration-tardive-page" class="pt-3 mb-6">
        <div class="bg-info text-center text-white" id="ariane">
            <b-button @click="getBack"> <i class="fa fa-chevron-left"></i> retour </b-button>
            Pointages
        </div>

        <div v-if="!interventions" class="my-5 text-white" style="height: 300px">Chargement...</div>
        <template v-else-if="interventions.length > 0">
            <div class="card bg-dark text-white form-group mt-5">
                <div class="card-body">
                    <h5 class="d-flex align-items-center justify-content-center m-0">
                        <div>
                            Vous avez <strong>{{ interventions.length }} intervention{{ interventions.length == 1 ? "" : "s" }}</strong> non
                            réalisée{{ interventions.length == 1 ? "" : "s" }}
                        </div>
                    </h5>
                </div>
            </div>

            <b-list-group class="mb-4">
                <div v-for="key in Object.keys(groupedInterventions)" :key="key">
                    <h3 class="text-white">{{ $moment(key).format("dddd D MMMM") | capitalize }}</h3>
                    <div class="mb-2" v-for="intervention in groupedInterventions[key]" :key="intervention.idintervention_ticking">
                        <div @click="selectIntervention(intervention)" class="list-group-item list-group-item-action">
                            <div class="centered-ico col-2 bg-success text-white"><i class="fa-2x fas fa-edit" aria-hidden="true"></i></div>
                            <div class="align-items-center row text-dark">
                                <div class="col-10 offset-2">
                                    <span class="d-block text-truncate">{{ getTimeString(intervention) }}</span>
                                    <span class="d-block text-truncate"
                                        >{{ intervention.customer_title }}. {{ getFormattedFirstname(intervention.customer_firstname) }}
                                        {{ intervention.customer_lastname }}</span
                                    >
                                    <span class="text-muted subtitle"
                                        ><i>{{ getFormattedAdress(intervention) }}</i></span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-list-group>
        </template>
        <template v-else>
            <div class="text-white my-5">
                <div class="row px-5 my-5 d-flex justify-content-center">
                    <i class="fas fa-6x mt-5 fa-thumbs-up"></i>
                </div>
                <h4 class="row text-center d-flex justify-content-center">
                    <div>
                        Bravo !<br />
                        Toutes les interventions<br />
                        planifiées ont été réalisées.
                    </div>
                </h4>
            </div>
        </template>

        <div class="d-flex flex-column align-items-center text-white">
            <div class="text-center">D'autres prestations à déclarer ?</div>
            <div class="mt-1">
                <a class="btn btn-danger" :href="`tel:${branchPhone}`">
                    <span><i class="fas fa-phone-alt mr-2"></i> Appeler {{ getCompanyName() }}</span>
                </a>
            </div>
        </div>

        <DeclarerPointage
            :intervention="selectedIntervention"
            :activities="activities"
            @submit="pointageCreated"
            ref="declarer-pointage"
        ></DeclarerPointage>
    </div>
</template>

<script>
import planningService from "../../../services/planningService";
import router from "@/router";
import userService from "@/services/userService";
import DeclarerPointage from "@/components/Pages/DeclarationTardive/Modals/DeclarerPointage";
import pointageService from "@/services/pointageService";

export default {
    name: "DeclarationTardive",
    components: { DeclarerPointage },
    data() {
        return {
            interventions: null,
            groupedInterventions: {},
            branchPhone: "0368100444",
            selectedIntervention: null,
            activities: [],
        };
    },
    mounted() {
        this.loadInterventionsByDate();
        userService.getContrats().then((res) => {
            if (res.data.etat == "KO") return;
            const contract = res.data?.data?.[0];
            let phone = this.branchPhone;

            if (contract && contract.company_type != "Propre" && contract.phone) {
                phone = contract.phone;
            }

            this.branchPhone = phone;
        });
        pointageService.getBusinessActivities().then((res) => {
            if (res.data.etat == "KO") return;
            this.activities = res.data.data;
        });
    },
    filters: {
        capitalize: function (value) {
            if (!value) return "";
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
    },
    methods: {
        getCompanyName() {
            if (!location.host.includes("family")) {
                return "MERCI+";
            } else {
                return "Family Sphere";
            }
        },
        getBack() {
            router.go(-1);
        },
        loadInterventionsByDate() {
            planningService
                .getPlanning(this.$moment().subtract(35, "days").format("YYYY-MM-DD"), this.$moment().format("YYYY-MM-DD"))
                .then((response) => {
                    if (response.data.etat === "OK") {
                        this.interventions = response.data.data.filter((i) => !i.idintervention_ticking && !i.canceled_category);
                        this.interventions.forEach((i) => {
                            if (!this.groupedInterventions[i.date_slot]) {
                                this.groupedInterventions[i.date_slot] = [];
                            }
                            this.groupedInterventions[i.date_slot].push(i);
                        });
                    }
                })
                .catch((error) => {
                    this.$rollbar.error("Erreur 3023", error);
                    this.$bvToast.toast("Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.", {
                        title: "Erreur 3023",
                        autoHideDelay: 5000,
                        variant: "danger",
                    });
                });
        },
        getTimeString(intervention) {
            return `${intervention.start_time.substring(0, 5)} à ${intervention.end_time.substring(0, 5)}`;
        },
        getFormattedFirstname(firstname) {
            if (firstname === null) return "";

            return firstname.charAt(0).toUpperCase() + firstname.slice(1).toLowerCase();
        },
        getFormattedAdress(intervention) {
            return `${intervention.adress1} ${intervention.postal_code} ${intervention.city}`;
        },
        selectIntervention(intervention) {
            this.selectedIntervention = intervention;
            this.$refs["declarer-pointage"].show();
        },
        pointageCreated() {
            this.$refs["declarer-pointage"].close();
            this.interventions = this.interventions.filter((i) => i != this.selectedIntervention);
            const key = this.selectedIntervention.date_slot;
            this.groupedInterventions[key] = this.groupedInterventions[key].filter((i) => i != this.selectedIntervention);
            if (this.groupedInterventions[key].length == 0) delete this.groupedInterventions[key];
            // this.selectedIntervention = null;
        },
    },
};
</script>

<style lang="scss" scoped>
.subtitle {
    font-size: smaller;
    opacity: 0.7;
}

#ariane {
    text-align: center;
    padding: 10px;
    position: fixed;
    font-size: 22px;
    top: 45px;
    left: 0;
    right: 0;
    z-index: 2;
}

@media (min-width: 992px) {
    #ariane {
        top: 80px;
    }
}

#ariane button {
    position: absolute;
    left: 15px;
    background: transparent;
    border: none;
}

#ariane button:focus {
    box-shadow: none;
}

#list {
    margin-top: 3.5rem;
}

.declarationPrestation {
    border: 1px solid #b70e0c;
    color: #b70e0c;
}
</style>
